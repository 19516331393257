import LocalizedStrings from "react-localization";
import English from "./languages/en";
import Spanish from "./languages/es";
import French from "./languages/fr";
import Hindi from "./languages/hi";
import Korean from "./languages/ko";
import Russian from "./languages/ru";
import Swahili from "./languages/sw";
import Tagalog from "./languages/tl";
import Vietnamese from "./languages/vi";
import ChineseSimplified from "./languages/zh";
import ChineseTraditional from "./languages/zh-TW";

const Strings = new LocalizedStrings({
    en: English,
    es: Spanish,
    fr: French,
    hi: Hindi,
    ko: Korean,
    ru: Russian,
    sw: Swahili,
    tl: Tagalog,
    vi: Vietnamese,
    zh: ChineseSimplified,
    "zh-TW": ChineseTraditional,
});

export { Strings };
