// @ts-nocheck
export default {
    welcome: "欢迎",
    email_address: "电子邮件地址",
    email_or_phone: "电子邮件地址或电话号码",
    enter_your_email: "输入您的电子邮件",
    enter_your_email_or_phone: "输入您的电子邮件地址或电话号码",
    phone_placeholder: "电话号码",
    password: "密码",
    confirm_password: "确认密码",
    log_in: "登录",
    login: "登录",
    forgot_password: "忘记密码？",
    forgot_password_title: "忘记密码",
    keep_me_logged_in: "保持登录状态",
    sign_in: "登录",
    not_registered: "还没有注册？",
    create_account: "创建账户",
    sign_up: "注册",
    first_name: "名字",
    last_name: "姓氏",
    dob_placeholder: "出生日期 (DD/MM/YYYY)",
    submit: "提交",
    already_have_account: "已经有账户？",
    why_need_dob_title: "为什么需要这个？",
    why_need_dob_text: `使用本网站即表示您确认、承认并同意您至少已年满14岁，或者如果您未满18岁但至少已年满14岁（“未成年人”），您是在父母或法定监护人的同意下使用本网站，并且您已获得父母或法定监护人的许可使用本网站并同意其条款。如果您是未成年人的父母或法定监护人，您在此同意将未成年人绑定于这些条款，并在未成年人违反任何这些条款时完全赔偿并使公司免受损害。如果您未满14岁，您不得在任何时间或以任何方式使用本网站或向公司或本网站提交任何信息。注意：本软件的某些实例要求用户至少年满14岁、16岁或18岁。如果您在输入出生日期后被拒绝注册，是因为您注册的特定门户网站有这些要求之一。`,
    go_back: "返回",
    email_verification: "电子邮件验证",
    log_out: "登出",
    resend_email_verification: "重新发送验证电子邮件",
    resend_text_verification: "重新发送验证短信",
    send_email_verification_instead: "改为发送验证电子邮件",
    send_text_verification_instead: "改为发送验证短信",
    reset_password: "重置密码",
    accept_invite: "接受邀请",
    my_hep: "我的家庭锻炼计划",
    plan_created: "计划已创建",
    remove_plan: "移除计划",
    remove_plan_confirm_text: "您确定要移除这个计划吗？",
    duplicate_plan_check_text:
        "您已经分配了这个计划。您确定要重新分配这个计划吗？",
    duplicate_plan_modal: {
        yes_text: "是",
        no_text: "转到仪表板",
    },
    translation_disclaimer: {
        title: "翻译免责声明",
        subtitle: "请注意，我们的翻译由人工智能软件提供支持，准确率约为 98%。为了确保清晰准确的沟通，我们建议要求对任何看似不清楚或不正确的翻译进行澄清。还建议使用简单的语言并避免使用俚语，以尽量减少潜在的误解。",
        accept_button_text: "我理解并同意自行承担使用风险",
    },
    provider: "提供者",
    self_help_text: "自助教育",
    show_all: "显示全部",
    no_record: "未找到记录",
    what_to_do: "该做什么",
    hold_time: "保持时间",
    day_per_week: "每周天数",
    equipment: "设备",
    sets: "组数",
    reps: "重复次数",
    weight: "重量",
    in_pounds: "以磅为单位",
    time_per_day: "每天时间",
    starting_position: "起始位置",
    description: "描述",
    what_you_feel: "您应该感觉到什么",
    too_easy: "太容易",
    too_hard: "太难",
    just_right: "刚刚好",
    exercise: "锻炼",
    education: "教育",
    back_to_list: "返回列表",
    about_this_exercise: "您对这项锻炼有什么看法？",
    attachement: "附件",
    exercise_completed: "锻炼完成",
    no_details: "没有可用的详细信息。",
    hep_details: {
        hard_exercise_modal: {
            title: "需要调整锻炼。",
            description_line1:
                "这是此策略中最简单的锻炼，因此我们目前无法提供更简单的锻炼。",
            description_line2:
                "如果您发现任何锻炼太难，有疑虑或不确定下一步该怎么做，请立即停止并咨询医疗专业人员或您的指定提供者（如果此恢复计划是由他们开具的）。",
            btn_text: "我已阅读并理解这些建议",
        },
    },
    flash_card: {
        yes_text: "是",
        no_text: "否",
    },
    complete_strategy_modal: {
        title: "恭喜！",
        description: "您已完成这组锻炼。",
        btn_text: "转到 HEP 列表",
    },
    talk_to_expert: {
        title: "与专家交谈",
        breadcrumb: "与专家交谈！",
        book_now: "立即预订",
        powered_by: "技术支持",
    },
    find_clinic: {
        title: "寻找诊所",
        search: "输入位置",
        breadcrumb: "寻找诊所",
        back_title: "位置",
        result_text: "搜索结果",
        pagination_label: "显示：",
        pagination_text: "条目",
        toggle_list_map_label: "查看：",
        list_text: "列表",
        map_text: "地图",
        print_text: "打印",
        not_found: "未找到诊所",
    },
    messages: {
        title: "消息",
        new_message: "新消息",
        welcome: "欢迎",
        you_are_signed_in_as: "您已登录为",
        hi: "嗨",
        you_are_disconnected: "您当前已断开互联网连接。",
        practice: "实践",
        provider: "提供者",
        could_not_send_msg: "无法发送消息。",
        some_files_removed: "一些超过100 MB的文件已被移除。",
        say_hello: "打个招呼",
        hello_text: "你好！",
        start_conversation: "开始您的新对话",
        send: "发送",
        textbox_placeholder: "开始输入...",
        search_placeholder: "搜索...",
        no_provider: "没有分配提供者。",
        no_active_chat: "您没有活跃的聊天。点击新消息按钮开始新的聊天。",
    },
    header_messages: {
        title: "消息",
        view_all: "查看全部",
        no_messages: "暂无消息",
    },
    support: {
        title: "支持",
        tech_support_email: "技术支持电子邮件",
        other_question: "其他问题",
        section1_title: "临床支持（医疗相关问题）",
        section1_description:
            "在危及生命或医疗紧急情况下，请立即拨打911。对于非紧急医疗咨询，请联系",
        section2_title: "技术支持（软件相关问题）",
        section2_description:
            "我们的技术支持时间为周一至周五，太平洋标准时间上午7:00至下午4:00，主要节假日除外。任何在这些时间之外提交的请求将在下一个工作日处理。",
        tech_support_email_address: "support@everflexplus.com",
    },
    injury_prevention_plan: {
        title: "伤害预防计划",
        heading: "目前没有受伤？整天坐在办公桌前？",
        description:
            "开始跑步计划，或想提高您的高尔夫球技？我们有锻炼可以帮助预防疼痛和伤害，并加强您需要提高表现的区域。",
        workplace_label: "工作场所计划",
        sport_label: "运动计划",
        speciality_label: "专业计划",
        generalized_label: "通用计划",
        start_plan: "开始计划",
    },
    footer: {
        copyright_text: "Everflex Plus Health | 版权所有",
        pat_no_text: "这些产品受美国专利号11,587,687和其他待决申请的保护。",
        about: "关于",
        security_statement: "安全声明",
        privacy_policy: "隐私政策",
        terms_conditions: "条款和条件",
        contact: "联系",
        support: "支持",
        faqs: "常见问题",
        patent_information_text: "专利信息",
    },
    my_profile: {
        head_title_text: "个人资料",
        my_account_text: "我的账户",
        tab: {
            account_information_text: "账户信息",
            communication_preferences_text: "通信偏好",
            change_password_text: "更改密码",
        },
        account_information_form: {
            page_heading_text: "账户信息",
            first_name_field: {
                label_text: "名字",
                placeholder_text: "输入名字",
            },
            last_name_field: {
                label_text: "姓氏",
                placeholder_text: "输入姓氏",
            },
            dob_field: {
                label_text: "出生日期",
                placeholder_text: "MM/DD/YYYY",
            },
            email_field: {
                label_text: "电子邮件/用户名",
                placeholder_text: "输入电子邮件/用户名",
            },
            phone_number_field: {
                label_text: "电话号码",
                placeholder_text: "输入电话号码",
            },
            otp_modal: {},
            unverified_email_text: "您的电子邮件/用户名未验证。",
            unverified_email_btn_text: "点击这里重新发送验证电子邮件。",
            verification_link_sent_text:
                "新的验证链接已发送到您的电子邮件地址。",
            verification_link_not_sent: "请提供电子邮件地址以进行验证。",
            submit_btn_text: "提交",
        },
        change_password_form: {
            page_heading_text: "登录信息",
            current_password_field: {
                label_text: "当前密码",
                placeholder_text: "输入当前密码",
            },
            new_password_field: {
                label_text: "新密码",
                placeholder_text: "输入新密码",
            },
            confirm_password_field: {
                label_text: "确认密码",
                placeholder_text: "输入确认密码",
            },
            submit_btn_text: "提交",
        },
        communication_preference_form: {
            page_heading_text: "通信偏好",
            table_headings: {
                setting_text: "设置",
                opt_in_text: "选择加入",
                opt_out_text: "选择退出",
            },
            table_body: {
                email_notification_text: "电子邮件通知",
                sms_notification_text: "短信通知",
            },
            submit_btn_text: "提交",
        },
    },
    password_requirement_text:
        "密码必须包含至少8个字符，包括大小写字母、数字和符号的组合。",
    language: {
        could_not_update: "无法更新语言。",
    },
    error_page: {
        go_back_text: "返回首页",
        403: {
            title: "403：禁止访问",
            description: "抱歉，您无权访问此页面。",
        },
        404: {
            title: "404：页面未找到",
            description: "抱歉，找不到您要查找的页面。",
        },
        500: {
            title: "500：服务器错误",
            description: "哎呀，我们的服务器出了点问题。",
        },
        503: {
            title: "503：服务不可用",
            description: "抱歉，我们正在进行一些维护。请稍后再来。",
        },
    },
    identify_injury: {
        select: {
            front: "正面",
            back: "背面",
            heading: "我的伤害",
            page_title: "我的伤害",
            okay: "好的",
            tell_me: "告诉我",
            about_pain: "关于您的疼痛",
            answer_question:
                "回答一些问题，并接收一系列个性化的基于证据和物理治疗师策划的锻炼，旨在帮助您轻轻缓解23种最常见的疾病。如果您不符合任何伤害模式，您可以通过我们的通用计划进行自助，找到您附近的物理治疗诊所，或在线与提供者进行会诊。",
            select_body_part: "选择一个您有问题的身体部位：",
            next: "下一步",
            body_part_not_found: "未找到身体部位。",
        },
        show: {
            no_question_found: "未找到问题",
            next: "下一步",
            heading: "识别我的伤害",
            page_title: "识别我的伤害",
            play_video_info:
                "播放视频以听取您的虚拟物理治疗师对问题的更深入解释。",
        },
        body_part: {
            heading: (bodyPart) => `探索${bodyPart}评估`,
            sub_heading: (currentBodyPart, newBodyPart) =>
                `在${currentBodyPart}锻炼中没有找到缓解？重定向以评估和解决相关的${newBodyPart}问题。`,
            explore_plans: "探索计划",
        },
        fallout: {
            heading: "根据您提供的信息，您没有符合我们的伤害模式之一。",
            sub_heading:
                "建议您与持牌物理治疗师交谈，以确保您获得最佳的护理。我们提供以下选项以满足您的需求：",
            talk_to_expert: "与专家交谈",
            find_clinic: "寻找诊所",
        },
        generalized: {
            heading: "探索通用计划",
            sub_heading:
                "没有找到您需要的？重定向到更广泛的选项，以管理您的疼痛或增强您的锻炼。",
            explore_plans: "探索计划",
            not_ready_to_speak_heading: "还没准备好与专家交谈吗？探索我们的总体计划。",
            not_ready_to_speak_sub_heading: "单击下面的更多选项来帮助控制您的疼痛或受伤",
        },
        hep: {
            heading: "做得好 🎉",
            sub_heading:
                "您的回答已成功匹配到一个已建立的伤害模式。我们已为您分配了一个与这些结果匹配的恢复计划。",
            get_started: "开始",
        },
        all_body_parts: {
            neck: "颈部",
            shoulder: "肩部",
            elbow_hand: "肘部和手部",
            elbow_arm: "肘部和手臂",
            wrist_hand: "手腕和手部",
            back: "背部",
            hip: "髋部",
            knee: "膝部",
            knee_leg: "膝部和腿部",
            ankle_foot: "踝部和足部",
        },
        something_went_wrong_self_help_logs: "保存您的自助日志时出了点问题。",
    },
    cms_pages: {
        about: {
            breadcrumb_text: "关于",
            head_text: "关于",
            title_text: "关于",
        },
        security_statement: {
            breadcrumb_text: "安全声明",
            head_text: "安全声明",
            title_text: "安全声明",
        },
        privacy: {
            breadcrumb_text: "隐私政策",
            head_text: "隐私政策",
            title_text: "隐私政策",
        },
        terms: {
            breadcrumb_text: "条款和条件",
            head_text: "条款和条件",
            title_text: "条款和条件",
        },
        faq: {
            breadcrumb_text: "常见问题",
            head_text: "常见问题",
            title_text: "常见问题",
        },
        patent: {
            breadcrumb_text: "专利信息",
            head_text: "专利信息",
            title_text: "专利信息",
        },
        no_data_text: "未找到数据！",
    },
    accept_terms: {
        i_agree_text: "我同意上述条款和条件。",
        yes_btn_text: "是",
        no_btn_text: "否",
    },
    hep_restart: {
        check_in: "是时候签到",
        completed: "您的计划已完成！",
        hey: "你好，",
        complete_plan_desc: `我们很高兴与您一起庆祝过去21天您所取得的惊人成就！`,
        take_a_moment: `花点时间反思一下您目前的感受。`,
        feeling_better: `感觉好些了？太棒了！`,
        current_plan_desc: `您可以点击下面的完成我的当前计划按钮。为您的健康干杯！`,
        resume_plan_desc: `要继续您的计划并保持势头，请点击下面的继续我的计划按钮。`,
        clinic_desc: `如果您没有取得太大进展或需要一些额外的支持，我们会支持您！暂停一下是可以的。我们建议与医疗专业人员交谈，他们可以提供个性化的指导。`,
        top_priority: `您的健康是我们的首要任务。`,
        support_you: `我们会在每一步都支持您。`,
        complete_plan_btn: `完成我的当前计划`,
        resume_plan_btn: `继续我的计划`,
        clinic_btn: "寻找您附近的医疗专业人员",
        keep_moment: "您想保持势头吗？",
        is_completed_resume_plan: `点击下面的继续我的计划按钮。`,
    },
    header_module: {
        notification_section: {
            popup_heading: "通知",
            view_all_text: "查看全部",
            no_data_text: "没有通知显示。",
        },
        select_preferred_language: "选择首选语言",
        my_account: "我的账户",
        sign_out: "登出",
        logout_go_back: "登出/返回",
    },
    notification_center: {
        list: {
            page_title: "通知中心列表",
            page_heading: "所有通知",
            no_record: "未找到通知",
        },
        show: {
            page_title: "通知中心显示",
            page_heading: "通知详情",
        },
        breadcrumb: {
            notification_text: "所有通知",
        },
        action: {
            view: "查看",
            delete: "删除",
            delete_confirm: "您确定要删除此通知吗？",
        },
    },
    general_error: "出了些问题。请稍后再试。",
    start_screen: {
        title: "输入您的电子邮件或电话号码",
        heading: "输入您的电子邮件或电话号码",
        email_placeholder: "输入您的电子邮件或电话号码",
        btn_text: "下一步",
        account_disabled_error: "您的账户已被禁用，请联系支持。",
        invite_sent: "邀请邮件已成功发送，请检查您的电子邮件。",
        invite_sent_by_text: "邀请短信已发送",
        send_invite_by_text: "改为发送邀请短信",
        account_not_found: "未找到账户！",
        choose_account: "选择您的诊所以继续！",
    },
    invite_modal: {
        heading: "我们找到了您的账户",
        sub_heading:
            "您的账户已创建，但注册过程尚未完成。请检查您的电子邮件以完成注册，或在必要时请求新的邀请。",
        btn_text: "重新发送账户邀请邮件",
        btn_text_sms: "重新发送账户邀请短信",
    },
    preference_modal: {
        acceptance_text: (clinicName) =>
            `我授权以下来自${clinicName}的通信通过提供的电子方式传送给我。我理解某些形式的电子通信可能不安全，存在向未经授权的个人不当披露的风险。`,
        acceptance_text_2: `我愿意接受这种风险，如果发生此类事件，我不追究诊所的责任。`,
        sms_notification_text: `短信通知提醒 - 当适用于您的康复计划并由您的提供者分配时`,
        email_notification_text: `电子邮件通知（重要更新、其他医疗相关信息的提醒和偶尔的营销消息）`,
        accept_btn_text: "接受",
        submit_btn_text: "提交",
        enter_phone: "输入您的手机号码",
        default_timezone_text: (defaultTimezone) =>
            `我们将在当地时间下午6:00发送短信提醒您做运动。看起来您位于${defaultTimezone}时区，对吗？`,
        timezone_required: "需要时区。",
        saved: "偏好已保存。",
        no_btn_text: "否",
        yes_btn_text: "是",
        select_timezone: "选择时区",
        select_your_timezone: "选择您的时区",
    },
    set_new_password: {
        heading: "设置您的新密码",
        sub_heading:
            "为了增强安全性，请输入管理员提供的当前密码，然后设置您的新密码。",
        current_password_label: "当前密码",
        current_password_placeholder: "输入当前密码",
        new_password_label: "新密码",
        new_password_placeholder: "输入新密码",
        confirm_password_label: "确认新密码",
        confirm_password_placeholder: "确认密码",
        save_btn_text: "保存密码",
    },
    hep_status: {
        completed: "已完成",
        self_assigned: "自我分配",
        independent: "独立家庭计划",
    },
    level: "级别",
    sec: "秒",
    days: "天",
    repetitions: "重复次数",
    lbs: "磅",
    time: "时间",
    allLanguages: {
        en: "英语",
        es: "西班牙语",
        fr: "法语",
        zh: "中文（简体）",
        "zh-TW": "中文（繁体）",
        tl: "他加禄语",
        vi: "越南语",
        ar: "阿拉伯语",
        ko: "韩语",
        ru: "俄语",
        hi: "印地语",
        sw: "斯瓦希里语",
    },
};
