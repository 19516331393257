// @ts-nocheck
export default {
    welcome: "Bienvenue",
    email_address: "Adresse e-mail",
    email_or_phone: "Adresse e-mail ou numéro de téléphone",
    enter_your_email: "Entrez votre e-mail",
    enter_your_email_or_phone:
        "Entrez votre adresse e-mail ou numéro de téléphone",
    phone_placeholder: "Numéro de téléphone",
    password: "Mot de passe",
    confirm_password: "Confirmez le mot de passe",
    log_in: "Se connecter",
    login: "Connexion",
    forgot_password: "Mot de passe oublié?",
    forgot_password_title: "Mot de passe oublié",
    keep_me_logged_in: "Rester connecté",
    sign_in: "Se connecter",
    not_registered: "Pas encore inscrit?",
    create_account: "Créer un compte",
    sign_up: "S'inscrire",
    first_name: "Prénom",
    last_name: "Nom de famille",
    dob_placeholder: "Date de naissance (JJ/MM/AAAA)",
    submit: "Soumettre",
    already_have_account: "Vous avez déjà un compte?",
    why_need_dob_title: "Pourquoi avons-nous besoin de cela?",
    why_need_dob_text: `En utilisant ce site, vous déclarez, reconnaissez et acceptez que vous avez au moins 14 ans, ou si vous avez moins de 18 ans mais au moins 14 ans (un "Mineur"), que vous utilisez le site avec le consentement de votre parent ou tuteur légal et que vous avez reçu la permission de votre parent ou tuteur légal d'utiliser le site et d'accepter ses conditions. Si vous êtes un parent ou tuteur légal d'un mineur, vous acceptez par la présente de lier le mineur à ces conditions et de pleinement indemniser et dégager de toute responsabilité la société si le mineur enfreint l'une de ces conditions. Si vous n'avez pas au moins 14 ans, vous ne pouvez pas utiliser le site à tout moment ou de quelque manière que ce soit ni soumettre d'informations à la société ou au site. REMARQUE : Il existe certaines instances de ce logiciel qui exigent que les utilisateurs aient au moins 14 ans, au moins 16 ans ou au moins 18 ans. Si votre inscription est refusée après avoir entré votre date de naissance, c'est parce que le portail spécifique pour lequel vous vous inscrivez a l'une de ces exigences en place.`,
    go_back: "Retourner",
    email_verification: "Vérification de l'e-mail",
    log_out: "Se déconnecter",
    resend_email_verification: "Renvoyer l'e-mail de vérification",
    resend_text_verification: "Renvoyer le texte de vérification",
    send_email_verification_instead:
        "Envoyer l'e-mail de vérification à la place",
    send_text_verification_instead:
        "Envoyer le texte de vérification à la place",
    reset_password: "Réinitialiser le mot de passe",
    accept_invite: "Accepter l'invitation",
    my_hep: "Mon plan d'exercice à domicile",
    plan_created: "Plan créé",
    remove_plan: "Supprimer le plan",
    remove_plan_confirm_text: "Êtes-vous sûr de vouloir supprimer ce plan?",
    duplicate_plan_check_text:
        "Vous avez déjà ce plan assigné. Êtes-vous sûr de vouloir réassigner ce plan?",
    duplicate_plan_modal: {
        yes_text: "Oui",
        no_text: "Aller au tableau de bord",
    },
    translation_disclaimer: {
        title: "Avis de non-responsabilité concernant la traduction",
        subtitle: "Veuillez noter que nos traductions sont alimentées par un logiciel d'IA, dont la précision est d'environ 98 %. Pour garantir une communication claire et précise, nous vous conseillons de demander des éclaircissements sur toute traduction qui semble floue ou incorrecte. Il est également recommandé d'utiliser un langage simple et d'éviter l'argot pour minimiser les malentendus potentiels.",
        accept_button_text: "JE COMPRENDS ET ACCEPTE D'UTILISER À MES PROPRES RISQUES",
    },
    provider: "Fournisseur",
    self_help_text: "Éducation à l'auto-assistance",
    show_all: "Afficher tout",
    no_record: "Aucun enregistrement trouvé",
    what_to_do: "Que faire",
    hold_time: "Temps de maintien",
    day_per_week: "Jour par semaine",
    equipment: "Équipement",
    sets: "Séries",
    reps: "Répétitions",
    weight: "Poids",
    in_pounds: "En livres",
    time_per_day: "Temps par jour",
    starting_position: "Position de départ",
    description: "Description",
    what_you_feel: "Ce que vous devriez ressentir",
    too_easy: "Trop facile",
    too_hard: "Trop difficile",
    just_right: "Juste bien",
    exercise: "Exercice",
    education: "Éducation",
    back_to_list: "Retour à la liste",
    about_this_exercise: "Que pensez-vous de cet exercice?",
    attachement: "Pièce jointe",
    exercise_completed: "Exercice terminé",
    no_details: "Aucun détail disponible.",
    hep_details: {
        hard_exercise_modal: {
            title: "Ajustement nécessaire pour l'exercice.",
            description_line1:
                "C'est l'exercice le plus facile de cette stratégie, donc nous ne pouvons pas en fournir un plus facile pour le moment.",
            description_line2:
                "Si vous trouvez un exercice trop difficile, avez des préoccupations ou ne savez pas quoi faire ensuite, arrêtez immédiatement et consultez un professionnel de la santé ou votre fournisseur assigné si ce plan de récupération a été prescrit.",
            btn_text: "J'ai lu et compris ces recommandations",
        },
    },
    flash_card: {
        yes_text: "Oui",
        no_text: "Non",
    },
    complete_strategy_modal: {
        title: "Félicitations!",
        description: "Vous avez terminé cet ensemble d'exercices.",
        btn_text: "Aller à la liste hep",
    },
    talk_to_expert: {
        title: "Parlez à un expert",
        breadcrumb: "Parlez à un expert!",
        book_now: "Réservez maintenant",
        powered_by: "alimenté par",
    },
    find_clinic: {
        title: "Trouver une clinique",
        search: "Entrez l'emplacement",
        breadcrumb: "Trouver une clinique",
        back_title: "Emplacements",
        result_text: "Résultats de recherche",
        pagination_label: "Afficher:",
        pagination_text: "Entrées",
        toggle_list_map_label: "Voir:",
        list_text: "Liste",
        map_text: "Carte",
        print_text: "imprimer",
        not_found: "Aucune clinique trouvée",
    },
    messages: {
        title: "Messages",
        new_message: "Nouveau message",
        welcome: "Bienvenue",
        you_are_signed_in_as: "Vous êtes connecté en tant que",
        hi: "Salut",
        you_are_disconnected: "Vous êtes actuellement déconnecté d'Internet.",
        practice: "Pratique",
        provider: "Fournisseur",
        could_not_send_msg: "Impossible d'envoyer le message.",
        some_files_removed:
            "Certains fichiers de plus de 100 Mo ont été supprimés.",
        say_hello: "Dites bonjour",
        hello_text: "Bonjour!",
        start_conversation: "Commencez votre nouvelle conversation",
        send: "Envoyer",
        textbox_placeholder: "Commencez à taper...",
        search_placeholder: "Rechercher...",
        no_provider: "Aucun fournisseur assigné.",
        no_active_chat:
            "Vous n'avez pas de discussions actives. Commencez une nouvelle discussion en cliquant sur le bouton nouveau message.",
    },
    header_messages: {
        title: "Messages",
        view_all: "VOIR TOUT",
        no_messages: "Aucun message à afficher pour le moment",
    },
    support: {
        title: "Support",
        tech_support_email: "Email du support technique",
        other_question: "Autres questions",
        section1_title: "Support clinique (problèmes de santé)",
        section1_description:
            "En cas d'urgence médicale ou de danger de mort, composez immédiatement le 911. Pour les questions de santé non urgentes, veuillez contacter",
        section2_title: "Support technique (problèmes logiciels)",
        section2_description:
            "Notre support technique est disponible de 7h00 à 16h00 PST, du lundi au vendredi, à l'exception des jours fériés. Toute soumission effectuée en dehors de ces heures sera traitée le jour ouvrable suivant.",
        tech_support_email_address: "support@everflexplus.com",
    },
    injury_prevention_plan: {
        title: "Plan de prévention des blessures",
        heading:
            "Vous n'avez pas de blessure actuellement ? Vous travaillez à un bureau toute la journée ?",
        description:
            "Vous commencez un programme de course ou vous voulez améliorer votre jeu de golf ? Nous avons des exercices qui aideront à prévenir la douleur et les blessures ainsi qu'à renforcer les zones que vous devez améliorer pour la performance.",
        workplace_label: "Plans de travail",
        sport_label: "Plans sportifs",
        speciality_label: "Plans spécialisés",
        generalized_label: "Plans généralisés",
        start_plan: "Commencer le plan",
    },
    footer: {
        copyright_text: "Everflex Plus Health | Tous droits réservés",
        pat_no_text:
            "Ces produits sont couverts par le brevet n° US 11,587,687 et d'autres demandes en cours.",
        about: "À propos",
        security_statement: "Déclaration de sécurité",
        privacy_policy: "Politique de confidentialité",
        terms_conditions: "Conditions générales",
        contact: "Contact",
        support: "Support",
        faqs: "FAQs",
        patent_information_text: "Informations sur les brevets",
    },
    my_profile: {
        head_title_text: "Profil",
        my_account_text: "Mon compte",
        tab: {
            account_information_text: "Informations sur le compte",
            communication_preferences_text: "Préférences de communication",
            change_password_text: "Changer le mot de passe",
        },
        account_information_form: {
            page_heading_text: "Informations sur le compte",
            first_name_field: {
                label_text: "Prénom",
                placeholder_text: "Entrez le prénom",
            },
            last_name_field: {
                label_text: "Nom de famille",
                placeholder_text: "Entrez le nom de famille",
            },
            dob_field: {
                label_text: "Date de naissance",
                placeholder_text: "MM/JJ/AAAA",
            },
            email_field: {
                label_text: "Email/Nom d'utilisateur",
                placeholder_text: "Entrez l'email/le nom d'utilisateur",
            },
            phone_number_field: {
                label_text: "Numéro de téléphone",
                placeholder_text: "Entrez le numéro de téléphone",
            },
            otp_modal: {},
            unverified_email_text:
                "Votre email/nom d'utilisateur n'est pas vérifié.",
            unverified_email_btn_text:
                "Cliquez ici pour renvoyer l'email de vérification.",
            verification_link_sent_text:
                "Un nouveau lien de vérification a été envoyé à votre adresse email.",
            verification_link_not_sent:
                "Veuillez fournir une adresse email pour la vérifier.",
            submit_btn_text: "Soumettre",
        },
        change_password_form: {
            page_heading_text: "Informations de connexion",
            current_password_field: {
                label_text: "Votre mot de passe actuel",
                placeholder_text: "Entrez le mot de passe actuel",
            },
            new_password_field: {
                label_text: "Nouveau mot de passe",
                placeholder_text: "Entrez le nouveau mot de passe",
            },
            confirm_password_field: {
                label_text: "Confirmer le mot de passe",
                placeholder_text: "Entrez le mot de passe de confirmation",
            },
            submit_btn_text: "Soumettre",
        },
        communication_preference_form: {
            page_heading_text: "Préférences de communication",
            table_headings: {
                setting_text: "Paramètre",
                opt_in_text: "S'inscrire",
                opt_out_text: "Se désinscrire",
            },
            table_body: {
                email_notification_text: "Notifications par email",
                sms_notification_text: "Notifications par SMS",
            },
            submit_btn_text: "Soumettre",
        },
    },
    password_requirement_text:
        "Le mot de passe doit contenir au minimum 8 caractères, comprenant un mélange de lettres majuscules et minuscules, de chiffres et de symboles.",
    language: {
        could_not_update: "Impossible de mettre à jour la langue.",
    },
    error_page: {
        go_back_text: "Retourner à l'accueil",
        403: {
            title: "403 : Interdit",
            description:
                "Désolé, vous n'êtes pas autorisé à accéder à cette page.",
        },
        404: {
            title: "404 : Page non trouvée",
            description: "Désolé, la page que vous recherchez est introuvable.",
        },
        500: {
            title: "500 : Erreur du serveur",
            description:
                "Oups, quelque chose s'est mal passé sur nos serveurs.",
        },
        503: {
            title: "503 : Service indisponible",
            description:
                "Désolé, nous effectuons une maintenance. Veuillez revenir bientôt.",
        },
    },

    identify_injury: {
        select: {
            front: "Avant",
            back: "Arrière",
            heading: "Ma blessure",
            page_title: "Ma blessure",
            okay: "D'accord",
            tell_me: "parlez-moi",
            about_pain: "de votre douleur",
            answer_question:
                "Répondez à quelques questions et recevez une série personnalisée d'exercices basés sur des preuves et conçus par des kinésithérapeutes pour vous aider à soulager doucement la douleur pour 23 des affections les plus couramment signalées. Si vous ne correspondez pas à un schéma de blessure, vous pouvez accéder à l'auto-assistance avec nos plans généralisés, trouver une clinique de kinésithérapie près de chez vous ou consulter un prestataire en ligne.",
            select_body_part:
                "Sélectionnez une partie du corps où vous avez des problèmes :",
            next: "Suivant",
            body_part_not_found: "Partie du corps non trouvée.",
        },
        show: {
            no_question_found: "Aucune question trouvée",
            next: "Suivant",
            heading: "Identifier ma blessure",
            page_title: "Identifier ma blessure",
            play_video_info:
                "Lancez la vidéo pour entendre une explication plus approfondie de la question de votre kinésithérapeute virtuel.",
        },
        body_part: {
            heading: (bodyPart) => `Explorer l'évaluation de ${bodyPart}`,
            sub_heading: (currentBodyPart, newBodyPart) =>
                `Vous n'avez pas trouvé de soulagement avec les exercices pour ${currentBodyPart} ? Redirigez-vous pour évaluer et traiter les préoccupations liées à ${newBodyPart}.`,
            explore_plans: "Explorer les plans",
        },
        fallout: {
            heading:
                "D'après les informations que vous avez fournies, vous ne correspondez pas à l'un de nos schémas de blessure.",
            sub_heading:
                "Il est recommandé de consulter un kinésithérapeute agréé pour vous assurer de recevoir les meilleurs soins possibles. Nous proposons les options suivantes pour répondre à vos besoins :",
            talk_to_expert: "Parlez à un expert",
            find_clinic: "Trouver une clinique",
        },
        generalized: {
            heading: "Explorer les plans généralisés",
            sub_heading:
                "Vous n'avez pas trouvé ce dont vous avez besoin ? Redirigez-vous vers une gamme plus large d'options pour gérer votre douleur ou améliorer vos exercices.",
            explore_plans: "Explorer les plans",
            not_ready_to_speak_heading: "Pas prêt à parler avec un expert ? Découvrez nos plans généraux.",
            not_ready_to_speak_sub_heading: "Cliquez ci-dessous pour plus d'options pour vous aider à gérer votre douleur ou votre blessure.",
        },
        hep: {
            heading: "Bien joué 🎉",
            sub_heading:
                "Vos réponses ont été correctement associées à un schéma de blessure établi. Nous vous avons attribué un plan de récupération correspondant à ces résultats.",
            get_started: "commencer",
        },
        all_body_parts: {
            neck: "Cou",
            shoulder: "Épaule",
            elbow_hand: "Coude et main",
            elbow_arm: "Coude / Bras",
            wrist_hand: "Poignet / Main",
            back: "Dos",
            hip: "Hanche",
            knee: "Genou",
            knee_leg: "Genou / Jambe",
            ankle_foot: "Cheville et pied",
        },
        something_went_wrong_self_help_logs:
            "Une erreur s'est produite lors de l'enregistrement de votre journal personnel.",
    },
    cms_pages: {
        about: {
            breadcrumb_text: "À propos",
            head_text: "À propos",
            title_text: "À propos",
        },
        security_statement: {
            breadcrumb_text: "Déclaration de sécurité",
            head_text: "Déclaration de sécurité",
            title_text: "Déclaration de sécurité",
        },
        privacy: {
            breadcrumb_text: "Politique de confidentialité",
            head_text: "Politique de confidentialité",
            title_text: "Politique de confidentialité",
        },
        terms: {
            breadcrumb_text: "Conditions générales",
            head_text: "Conditions générales",
            title_text: "Conditions générales",
        },
        faq: {
            breadcrumb_text: "Questions fréquemment posées",
            head_text: "Questions fréquemment posées",
            title_text: "Questions fréquemment posées",
        },
        patent: {
            breadcrumb_text: "Informations sur les brevets",
            head_text: "Informations sur les brevets",
            title_text: "Informations sur les brevets",
        },
        no_data_text: "Aucune donnée trouvée !",
    },
    accept_terms: {
        i_agree_text: "J'accepte les termes et conditions énoncés ci-dessus.",
        yes_btn_text: "Oui",
        no_btn_text: "Non",
    },
    hep_restart: {
        check_in: "Temps de faire le point",
        completed: "Votre plan est terminé !",
        hey: "Bonjour,",
        complete_plan_desc: `Nous sommes ravis de célébrer avec vous les progrès incroyables que vous avez réalisés au cours des 21 derniers jours !`,
        take_a_moment: `Prenez un moment pour réfléchir à ce que vous ressentez jusqu'à présent.`,
        feeling_better: `Vous vous sentez mieux ? Génial !`,
        current_plan_desc: `Vous pouvez cliquer sur le bouton TERMINER MON PLAN ACTUEL ci-dessous. À votre santé !`,
        resume_plan_desc: `Pour continuer votre plan et maintenir l'élan, cliquez sur le bouton REPRENDRE MON PLAN ci-dessous.`,
        clinic_desc: `Si vous n'avez pas beaucoup progressé ou si vous avez besoin d'un soutien supplémentaire, nous sommes là pour vous ! Il est normal de faire une pause. Nous vous recommandons de discuter avec un professionnel de la santé qui pourra vous fournir des conseils personnalisés.`,
        top_priority: `Votre bien-être est notre priorité absolue.`,
        support_you: `Nous sommes là pour vous soutenir à chaque étape.`,
        complete_plan_btn: `Terminer mon plan actuel`,
        resume_plan_btn: `Reprendre mon plan actuel`,
        clinic_btn: "Trouver un professionnel de la santé près de chez vous",
        keep_moment: "Souhaitez-vous maintenir l'élan ?",
        is_completed_resume_plan: `Cliquez sur le bouton REPRENDRE MON PLAN ci-dessous.`,
    },
    header_module: {
        notification_section: {
            popup_heading: "Notifications",
            view_all_text: "Voir tout",
            no_data_text: "Il n'y a aucune notification à afficher.",
        },
        select_preferred_language: "Sélectionner la langue préférée",
        my_account: "Mon compte",
        sign_out: "Se déconnecter",
        logout_go_back: "Se déconnecter/Revenir",
    },
    notification_center: {
        list: {
            page_title: "Liste du Centre de Notifications",
            page_heading: "Toutes les Notifications",
            no_record: "Aucune Notification Trouvée",
        },
        show: {
            page_title: "Afficher le Centre de Notifications",
            page_heading: "Détail de la Notification",
        },
        breadcrumb: {
            notification_text: "Toutes les Notifications",
        },
        action: {
            view: "Voir",
            delete: "Supprimer",
            delete_confirm:
                "Êtes-vous sûr de vouloir supprimer cette notification?",
        },
    },
    general_error:
        "Quelque chose s'est mal passé. Veuillez réessayer plus tard.",
    start_screen: {
        title: "Entrez votre email ou numéro de téléphone",
        heading: "Entrez votre email ou numéro de téléphone",
        email_placeholder: "Entrez votre email ou numéro de téléphone",
        btn_text: "Suivant",
        account_disabled_error:
            "Votre compte est désactivé, veuillez contacter le support.",
        invite_sent:
            "Email d'invitation envoyé avec succès, veuillez vérifier votre email.",
        invite_sent_by_text: "Texte d'invitation envoyé",
        send_invite_by_text: "Envoyer l'invitation par SMS à la place",
        account_not_found: "Compte non trouvé!",
        choose_account: "Choisissez votre clinique pour continuer!",
    },
    invite_modal: {
        heading: "Nous avons trouvé votre compte",
        sub_heading:
            "Votre compte a été créé, mais le processus d'inscription est incomplet. Veuillez vérifier votre email pour une invitation à compléter votre inscription, ou demander une nouvelle invitation si nécessaire.",
        btn_text: "Renvoyer l'email d'invitation du compte",
        btn_text_sms: "Renvoyer le SMS d'invitation du compte",
    },
    preference_modal: {
        acceptance_text: (clinicName) =>
            `J'autorise que les communications suivantes de ${clinicName} me soient envoyées par les moyens électroniques fournis. Je comprends que certaines formes de communications électroniques peuvent ne pas être sécurisées, créant un risque de divulgation inappropriée à des personnes non autorisées.`,
        acceptance_text_2: `Je suis prêt à accepter ce risque et ne tiendrai pas la pratique responsable en cas d'incident.`,
        sms_notification_text: `Notifications par SMS (rappels - lorsque cela est approprié pour votre plan de récupération et assigné par votre fournisseur)`,
        email_notification_text: `Notifications par email (mises à jour importantes, rappels d'autres informations liées à la santé et messages marketing occasionnels)`,
        accept_btn_text: "Accepter",
        submit_btn_text: "Soumettre",
        enter_phone: "ENTREZ VOTRE NUMÉRO DE TÉLÉPHONE MOBILE",
        default_timezone_text: (defaultTimezone) =>
            `Nous vous enverrons un rappel par SMS pour faire vos exercices à 18h00 heure locale. Il semble que vous soyez dans le fuseau horaire ${defaultTimezone}, est-ce correct?`,
        timezone_required: "Le fuseau horaire est requis.",
        saved: "Les préférences ont été enregistrées.",
        no_btn_text: "Non",
        yes_btn_text: "Oui",
        select_timezone: "Sélectionner le fuseau horaire",
        select_your_timezone: "Sélectionnez votre fuseau horaire",
    },
    set_new_password: {
        heading: "DÉFINISSEZ VOTRE NOUVEAU MOT DE PASSE",
        sub_heading:
            "Pour une sécurité accrue, veuillez entrer votre mot de passe actuel fourni par l'administrateur, puis définissez votre nouveau mot de passe.",
        current_password_label: "Mot de passe actuel",
        current_password_placeholder: "Entrez le mot de passe actuel",
        new_password_label: "Nouveau mot de passe",
        new_password_placeholder: "Entrez le nouveau mot de passe",
        confirm_password_label: "Confirmer le nouveau mot de passe",
        confirm_password_placeholder: "Confirmez le mot de passe",
        save_btn_text: "Enregistrer le mot de passe",
    },
    hep_status: {
        completed: "Terminé",
        self_assigned: "Auto-assigné",
        independent: "Programme à domicile indépendant",
    },
    level: "Niveau",
    sec: "Sec",
    days: "Jours",
    repetitions: "Répétitions",
    lbs: "Lbs",
    time: "Temps",
    allLanguages: {
        en: "Anglais",
        es: "Espagnol",
        fr: "Français",
        zh: "Chinois (Simplifié)",
        "zh-TW": "Chinois (Traditionnel)",
        tl: "Tagalog",
        vi: "Vietnamien",
        ar: "Arabe",
        ko: "Coréen",
        ru: "Russe",
        hi: "Hindi",
        sw: "Swahili",
    },
};
