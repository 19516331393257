// @ts-nocheck
export default {
    welcome: "환영합니다",
    email_address: "이메일 주소",
    email_or_phone: "이메일 주소 또는 전화번호",
    enter_your_email: "이메일을 입력하세요",
    enter_your_email_or_phone: "이메일 주소 또는 전화번호를 입력하세요",
    phone_placeholder: "전화번호",
    password: "비밀번호",
    confirm_password: "비밀번호 확인",
    log_in: "로그인",
    login: "로그인",
    forgot_password: "비밀번호를 잊으셨나요?",
    forgot_password_title: "비밀번호 찾기",
    keep_me_logged_in: "로그인 상태 유지",
    sign_in: "로그인",
    not_registered: "아직 등록하지 않으셨나요?",
    create_account: "계정 만들기",
    sign_up: "가입하기",
    first_name: "이름",
    last_name: "성",
    dob_placeholder: "생년월일 (DD/MM/YYYY)",
    submit: "제출",
    already_have_account: "이미 계정이 있으신가요?",
    why_need_dob_title: "왜 이 정보가 필요한가요?",
    why_need_dob_text: `이 사이트를 사용함으로써, 귀하는 최소 14세 이상임을 인정하고 동의합니다. 만약 귀하가 18세 미만이지만 14세 이상인 경우(“미성년자”), 귀하는 부모 또는 법적 보호자의 동의 하에 사이트를 사용하고 있으며, 부모 또는 법적 보호자의 허락을 받아 사이트를 사용하고 사이트의 약관에 동의한 것입니다. 만약 귀하가 미성년자의 부모 또는 법적 보호자라면, 귀하는 미성년자를 이 약관에 구속시키는 것에 동의하며, 미성년자가 이 약관을 위반할 경우 회사에 대해 전적으로 배상하고 면책할 것을 동의합니다. 만약 귀하가 최소 14세가 되지 않았다면, 귀하는 이 사이트를 어떤 경우에도 사용할 수 없으며, 회사나 사이트에 어떠한 정보도 제출할 수 없습니다. 참고: 이 소프트웨어의 일부 인스턴스는 사용자가 최소 14세, 최소 16세 또는 최소 18세 이상이어야 합니다. 생년월일을 입력한 후 등록이 거부된 경우, 이는 귀하가 등록하려는 특정 포털에 이러한 요구 사항 중 하나가 적용되기 때문입니다.`,
    go_back: "돌아가기",
    email_verification: "이메일 인증",
    log_out: "로그아웃",
    resend_email_verification: "인증 이메일 재전송",
    resend_text_verification: "인증 문자 재전송",
    send_email_verification_instead: "대신 인증 이메일 보내기",
    send_text_verification_instead: "대신 인증 문자 보내기",
    reset_password: "비밀번호 재설정",
    accept_invite: "초대 수락",
    my_hep: "내 홈 운동 계획",
    plan_created: "계획 생성됨",
    remove_plan: "계획 제거",
    remove_plan_confirm_text: "이 계획을 제거하시겠습니까?",
    duplicate_plan_check_text:
        "이미 이 계획이 할당되었습니다. 이 계획을 다시 할당하시겠습니까?",
    duplicate_plan_modal: {
        yes_text: "예",
        no_text: "대시보드로 이동",
    },
    translation_disclaimer: {
        title: "번역 면책조항",
        subtitle: "우리의 번역은 AI 소프트웨어를 통해 이루어지며 정확도는 약 98%입니다. 명확하고 정확한 의사소통을 보장하기 위해 불분명하거나 부정확해 보이는 번역에 대한 설명을 요청하는 것이 좋습니다. 또한 오해를 최소화하기 위해 간단한 언어를 사용하고 속어를 피하는 것이 좋습니다.",
        accept_button_text: "번역 면책조항",
    },
    provider: "제공자",
    self_help_text: "자기 도움 교육",
    show_all: "모두 보기",
    no_record: "기록이 없습니다",
    what_to_do: "해야 할 일",
    hold_time: "유지 시간",
    day_per_week: "주당 일수",
    equipment: "장비",
    sets: "세트",
    reps: "반복",
    weight: "무게",
    in_pounds: "파운드 단위",
    time_per_day: "일일 시간",
    starting_position: "시작 위치",
    description: "설명",
    what_you_feel: "느껴야 할 것",
    too_easy: "너무 쉬움",
    too_hard: "너무 어려움",
    just_right: "딱 맞음",
    exercise: "운동",
    education: "교육",
    back_to_list: "목록으로 돌아가기",
    about_this_exercise: "이 운동에 대해 어떻게 생각하십니까?",
    attachement: "첨부 파일",
    exercise_completed: "운동 완료",
    no_details: "세부 정보가 없습니다.",
    hep_details: {
        hard_exercise_modal: {
            title: "운동 조정 필요.",
            description_line1:
                "이 전략에서 가장 쉬운 운동이므로, 현재 더 쉬운 운동을 제공할 수 없습니다.",
            description_line2:
                "어떤 운동이 너무 어렵거나 걱정이 되거나 다음에 무엇을 해야 할지 확신이 서지 않는 경우, 즉시 중단하고 의료 전문가나 처방된 경우 지정된 제공자와 상담하십시오.",
            btn_text: "이 권장 사항을 읽고 이해했습니다",
        },
    },
    flash_card: {
        yes_text: "예",
        no_text: "아니요",
    },
    complete_strategy_modal: {
        title: "축하합니다!",
        description: "이 운동 세트를 완료했습니다.",
        btn_text: "hep 목록으로 이동",
    },
    talk_to_expert: {
        title: "전문가와 상담",
        breadcrumb: "전문가와 상담!",
        book_now: "지금 예약",
        powered_by: "제공",
    },
    find_clinic: {
        title: "클리닉 찾기",
        search: "위치 입력",
        breadcrumb: "클리닉 찾기",
        back_title: "위치",
        result_text: "검색 결과",
        pagination_label: "표시:",
        pagination_text: "항목",
        toggle_list_map_label: "보기:",
        list_text: "목록",
        map_text: "지도",
        print_text: "인쇄",
        not_found: "클리닉을 찾을 수 없습니다",
    },
    messages: {
        title: "메시지",
        new_message: "새 메시지",
        welcome: "환영합니다",
        you_are_signed_in_as: "로그인 상태:",
        hi: "안녕하세요",
        you_are_disconnected: "현재 인터넷 연결이 끊어졌습니다.",
        practice: "연습",
        provider: "제공자",
        could_not_send_msg: "메시지를 보낼 수 없습니다.",
        some_files_removed: "100MB 이상의 파일이 제거되었습니다.",
        say_hello: "인사하기",
        hello_text: "안녕하세요!",
        start_conversation: "새 대화 시작",
        send: "보내기",
        textbox_placeholder: "입력 시작...",
        search_placeholder: "검색...",
        no_provider: "할당된 제공자가 없습니다.",
        no_active_chat:
            "활성 채팅이 없습니다. 새 메시지 버튼을 클릭하여 새 채팅을 시작하세요.",
    },
    header_messages: {
        title: "메시지",
        view_all: "모두 보기",
        no_messages: "표시할 메시지가 아직 없습니다",
    },
    support: {
        title: "지원",
        tech_support_email: "기술 지원 이메일",
        other_question: "기타 질문",
        section1_title: "임상 지원 (의료 관련 문제)",
        section1_description:
            "생명을 위협하는 응급 상황이나 의료 응급 상황의 경우, 즉시 911에 전화하십시오. 비응급 의료 문의는 다음으로 연락하십시오",
        section2_title: "기술 지원 (소프트웨어 관련 문제)",
        section2_description:
            "기술 지원은 월요일부터 금요일까지 주요 공휴일을 제외하고 오전 7시부터 오후 4시까지 PST 시간에 제공됩니다. 이 시간 외에 제출된 문의는 다음 영업일에 처리됩니다.",
        tech_support_email_address: "support@everflexplus.com",
    },
    injury_prevention_plan: {
        title: "부상 예방 계획",
        heading: "현재 부상이 없으신가요? 하루 종일 책상에서 일하시나요?",
        description:
            "달리기 프로그램을 시작하거나 골프 게임을 향상시키고 싶으신가요? 우리는 통증과 부상을 예방하고 성능을 향상시키기 위해 필요한 부위를 강화하는 운동을 제공합니다.",
        workplace_label: "직장 계획",
        sport_label: "스포츠 계획",
        speciality_label: "특수 계획",
        generalized_label: "일반 계획",
        start_plan: "계획 시작",
    },
    footer: {
        copyright_text: "Everflex Plus Health | 모든 권리 보유",
        pat_no_text:
            "이 제품은 미국 특허 번호 US 11,587,687 및 기타 출원 중인 특허로 보호됩니다.",
        about: "소개",
        security_statement: "보안 성명",
        privacy_policy: "개인정보 보호정책",
        terms_conditions: "이용 약관",
        contact: "연락처",
        support: "지원",
        faqs: "자주 묻는 질문",
        patent_information_text: "특허 정보",
    },
    my_profile: {
        head_title_text: "프로필",
        my_account_text: "내 계정",
        tab: {
            account_information_text: "계정 정보",
            communication_preferences_text: "통신 선호도",
            change_password_text: "비밀번호 변경",
        },
        account_information_form: {
            page_heading_text: "계정 정보",
            first_name_field: {
                label_text: "이름",
                placeholder_text: "이름 입력",
            },
            last_name_field: {
                label_text: "성",
                placeholder_text: "성 입력",
            },
            dob_field: {
                label_text: "생년월일",
                placeholder_text: "MM/DD/YYYY",
            },
            email_field: {
                label_text: "이메일/사용자 이름",
                placeholder_text: "이메일/사용자 이름 입력",
            },
            phone_number_field: {
                label_text: "전화번호",
                placeholder_text: "전화번호 입력",
            },
            otp_modal: {},
            unverified_email_text: "이메일/사용자 이름이 인증되지 않았습니다.",
            unverified_email_btn_text:
                "여기를 클릭하여 인증 이메일을 다시 보내십시오.",
            verification_link_sent_text:
                "새 인증 링크가 이메일 주소로 전송되었습니다.",
            verification_link_not_sent:
                "인증하려면 이메일 주소를 제공하십시오.",
            submit_btn_text: "제출",
        },
        change_password_form: {
            page_heading_text: "로그인 정보",
            current_password_field: {
                label_text: "현재 비밀번호",
                placeholder_text: "현재 비밀번호 입력",
            },
            new_password_field: {
                label_text: "새 비밀번호",
                placeholder_text: "새 비밀번호 입력",
            },
            confirm_password_field: {
                label_text: "비밀번호 확인",
                placeholder_text: "비밀번호 확인 입력",
            },
            submit_btn_text: "제출",
        },
        communication_preference_form: {
            page_heading_text: "통신 선호도",
            table_headings: {
                setting_text: "설정",
                opt_in_text: "옵트인",
                opt_out_text: "옵트아웃",
            },
            table_body: {
                email_notification_text: "이메일 알림",
                sms_notification_text: "문자(SMS) 알림",
            },
            submit_btn_text: "제출",
        },
    },
    password_requirement_text:
        "비밀번호는 대문자와 소문자, 숫자, 기호를 혼합하여 최소 8자 이상이어야 합니다.",
    language: {
        could_not_update: "언어를 업데이트할 수 없습니다.",
    },
    error_page: {
        go_back_text: "홈으로 돌아가기",
        403: {
            title: "403: 접근 금지",
            description: "죄송합니다. 이 페이지에 접근할 수 없습니다.",
        },
        404: {
            title: "404: 페이지를 찾을 수 없음",
            description: "죄송합니다. 찾고 있는 페이지를 찾을 수 없습니다.",
        },
        500: {
            title: "500: 서버 오류",
            description: "죄송합니다. 서버에 문제가 발생했습니다.",
        },
        503: {
            title: "503: 서비스 이용 불가",
            description:
                "죄송합니다. 유지보수를 진행 중입니다. 곧 다시 확인해 주세요.",
        },
    },
    identify_injury: {
        select: {
            front: "앞",
            back: "뒤",
            heading: "내 부상",
            page_title: "내 부상",
            okay: "확인",
            tell_me: "알려주세요",
            about_pain: "통증에 대해",
            answer_question:
                "몇 가지 질문에 답하고, 23개의 가장 일반적으로 보고된 질환에 대해 개인 맞춤형 증거 기반 및 물리 치료사가 큐레이션한 운동 시리즈를 받아보세요. 부상 패턴에 맞지 않는 경우, 일반 계획을 통해 자기 도움을 받을 수 있으며, 근처 물리 치료 클리닉을 찾거나 온라인으로 제공자와 상담할 수 있습니다.",
            select_body_part: "문제가 있는 신체 부위를 선택하세요:",
            next: "다음",
            body_part_not_found: "신체 부위를 찾을 수 없습니다.",
        },
        show: {
            no_question_found: "질문을 찾을 수 없습니다",
            next: "다음",
            heading: "내 부상 식별",
            page_title: "내 부상 식별",
            play_video_info:
                "비디오를 재생하여 가상 물리 치료사의 질문에 대한 자세한 설명을 들어보세요.",
        },
        body_part: {
            heading: (bodyPart) => `${bodyPart} 평가 탐색`,
            sub_heading: (currentBodyPart, newBodyPart) =>
                `${currentBodyPart} 운동에서 완화되지 않았습니까? 관련 ${newBodyPart} 문제를 평가하고 해결하기 위해 리디렉션합니다.`,
            explore_plans: "계획 탐색",
        },
        fallout: {
            heading:
                "제공한 정보에 따라, 귀하는 우리의 부상 패턴에 맞지 않습니다.",
            sub_heading:
                "최상의 치료를 받기 위해서는 면허가 있는 물리 치료사와 상담하는 것이 좋습니다. 귀하의 필요에 맞는 다음 옵션을 제공합니다:",
            talk_to_expert: "전문가와 상담",
            find_clinic: "클리닉 찾기",
        },
        generalized: {
            heading: "일반 계획 탐색",
            sub_heading:
                "필요한 것을 찾지 못하셨나요? 통증 관리 또는 운동 향상을 위한 더 넓은 범위의 옵션으로 리디렉션합니다.",
            explore_plans: "계획 탐색",
            not_ready_to_speak_heading: "아직 전문가와 상담할 준비가 되지 않으셨나요? 우리의 일반 계획을 살펴보세요.",
            not_ready_to_speak_sub_heading: "통증이나 부상을 관리하는 데 도움이 되는 추가 옵션을 보려면 아래를 클릭하세요.",
        },
        hep: {
            heading: "잘 했어요 🎉",
            sub_heading:
                "귀하의 응답이 성공적으로 확립된 부상 패턴과 일치했습니다. 이러한 결과에 맞는 회복 계획을 할당했습니다.",
            get_started: "시작하기",
        },
        all_body_parts: {
            neck: "목",
            shoulder: "어깨",
            elbow_hand: "팔꿈치 및 손",
            elbow_arm: "팔꿈치/팔",
            wrist_hand: "손목/손",
            back: "등",
            hip: "엉덩이",
            knee: "무릎",
            knee_leg: "무릎/다리",
            ankle_foot: "발목 및 발",
        },
        something_went_wrong_self_help_logs:
            "자기 로그를 저장하는 중에 문제가 발생했습니다.",
    },
    cms_pages: {
        about: {
            breadcrumb_text: "소개",
            head_text: "소개",
            title_text: "소개",
        },
        security_statement: {
            breadcrumb_text: "보안 성명",
            head_text: "보안 성명",
            title_text: "보안 성명",
        },
        privacy: {
            breadcrumb_text: "개인정보 보호정책",
            head_text: "개인정보 보호정책",
            title_text: "개인정보 보호정책",
        },
        terms: {
            breadcrumb_text: "이용 약관",
            head_text: "이용 약관",
            title_text: "이용 약관",
        },
        faq: {
            breadcrumb_text: "자주 묻는 질문",
            head_text: "자주 묻는 질문",
            title_text: "자주 묻는 질문",
        },
        patent: {
            breadcrumb_text: "특허 정보",
            head_text: "특허 정보",
            title_text: "특허 정보",
        },
        no_data_text: "데이터를 찾을 수 없습니다!",
    },
    accept_terms: {
        i_agree_text: "위의 약관에 동의합니다.",
        yes_btn_text: "예",
        no_btn_text: "아니요",
    },
    hep_restart: {
        check_in: "체크인 시간",
        completed: "계획이 완료되었습니다!",
        hey: "안녕하세요,",
        complete_plan_desc: "지난 21일 동안의 놀라운 진전을 축하합니다!",
        take_a_moment: "지금까지의 느낌을 잠시 생각해 보세요.",
        feeling_better: "더 나아지셨나요? 멋져요!",
        current_plan_desc:
            "아래의 현재 계획 완료 버튼을 클릭하세요. 건강을 기원합니다!",
        resume_plan_desc:
            "계획을 계속하고 모멘텀을 유지하려면 아래의 계획 계속 버튼을 클릭하세요.",
        clinic_desc:
            "많은 진전을 보지 못했거나 추가 지원이 필요한 경우, 저희가 도와드리겠습니다! 잠시 멈추는 것도 괜찮습니다. 개인 맞춤형 지침을 제공할 수 있는 의료 전문가와 상담하는 것이 좋습니다.",
        top_priority: "귀하의 웰빙이 우리의 최우선 과제입니다.",
        support_you: "저희는 귀하를 모든 단계에서 지원합니다.",
        complete_plan_btn: "현재 계획 완료",
        resume_plan_btn: "현재 계획 계속",
        clinic_btn: "근처 의료 전문가 찾기",
        keep_moment: "모멘텀을 유지하시겠습니까?",
        is_completed_resume_plan: "아래의 계획 계속 버튼을 클릭하세요.",
    },
    header_module: {
        notification_section: {
            popup_heading: "알림",
            view_all_text: "모두 보기",
            no_data_text: "표시할 알림이 없습니다.",
        },
        select_preferred_language: "선호하는 언어 선택",
        my_account: "내 계정",
        sign_out: "로그아웃",
        logout_go_back: "로그아웃/돌아가기",
    },
    notification_center: {
        list: {
            page_title: "알림 센터 목록",
            page_heading: "모든 알림",
            no_record: "알림이 없습니다",
        },
        show: {
            page_title: "알림 센터 보기",
            page_heading: "알림 세부 정보",
        },
        breadcrumb: {
            notification_text: "모든 알림",
        },
        action: {
            view: "보기",
            delete: "삭제",
            delete_confirm: "이 알림을 삭제하시겠습니까?",
        },
    },
    general_error: "문제가 발생했습니다. 나중에 다시 시도해 주세요.",
    start_screen: {
        title: "이메일 또는 전화번호를 입력하세요",
        heading: "이메일 또는 전화번호를 입력하세요",
        email_placeholder: "이메일 또는 전화번호를 입력하세요",
        btn_text: "다음",
        account_disabled_error:
            "계정이 비활성화되었습니다. 지원팀에 문의하세요.",
        invite_sent:
            "초대 이메일이 성공적으로 전송되었습니다. 이메일을 확인하세요.",
        invite_sent_by_text: "초대 문자 전송됨",
        send_invite_by_text: "문자로 초대 전송",
        account_not_found: "계정을 찾을 수 없습니다!",
        choose_account: "계속하려면 클리닉을 선택하세요!",
    },
    invite_modal: {
        heading: "계정을 찾았습니다",
        sub_heading:
            "계정이 생성되었지만 가입 절차가 완료되지 않았습니다. 등록을 완료하기 위한 초대 이메일을 확인하거나 필요한 경우 새 초대를 요청하세요.",
        btn_text: "계정 초대 이메일 재전송",
        btn_text_sms: "계정 초대 문자 재전송",
    },
    preference_modal: {
        acceptance_text: (clinicName) =>
            `저는 ${clinicName}에서 제공하는 다음 통신이 제공된 전자 수단을 통해 전달되는 것을 허용합니다. 일부 전자 통신 형태는 안전하지 않을 수 있으며, 승인되지 않은 개인에게 부적절하게 공개될 위험이 있다는 것을 이해합니다.`,
        acceptance_text_2: `저는 그 위험을 감수할 의향이 있으며, 그러한 사건이 발생할 경우 실습을 책임지지 않을 것입니다.`,
        sms_notification_text: `문자 (SMS) 알림 - 회복 계획에 적합하고 제공자가 할당한 경우`,
        email_notification_text: `이메일 알림 (중요 업데이트, 기타 건강 관련 정보 알림 및 가끔 마케팅 메시지)`,
        accept_btn_text: "수락",
        submit_btn_text: "제출",
        enter_phone: "휴대폰 번호를 입력하세요",
        default_timezone_text: (defaultTimezone) =>
            `운동을 할 수 있도록 오후 6시에 문자 알림을 보내드리겠습니다. ${defaultTimezone} 시간대에 있는 것 같습니다. 맞습니까?`,
        timezone_required: "시간대가 필요합니다.",
        saved: "선호 사항이 저장되었습니다.",
        no_btn_text: "아니요",
        yes_btn_text: "예",
        select_timezone: "시간대 선택",
        select_your_timezone: "시간대를 선택하세요",
    },
    set_new_password: {
        heading: "새 비밀번호 설정",
        sub_heading:
            "보안을 강화하기 위해 관리자에게 제공된 현재 비밀번호를 입력한 후 새 비밀번호를 설정하세요.",
        current_password_label: "현재 비밀번호",
        current_password_placeholder: "현재 비밀번호를 입력하세요",
        new_password_label: "새 비밀번호",
        new_password_placeholder: "새 비밀번호를 입력하세요",
        confirm_password_label: "새 비밀번호 확인",
        confirm_password_placeholder: "비밀번호 확인",
        save_btn_text: "비밀번호 저장",
    },
    hep_status: {
        completed: "완료됨",
        self_assigned: "자가 할당됨",
        independent: "독립적인 홈 프로그램",
    },
    level: "레벨",
    sec: "초",
    days: "일",
    repetitions: "반복",
    lbs: "파운드",
    time: "시간",
    allLanguages: {
        en: "영어",
        es: "스페인어",
        fr: "프랑스어",
        zh: "중국어 (간체)",
        "zh-TW": "중국어 (번체)",
        tl: "타갈로그어",
        vi: "베트남어",
        ar: "아랍어",
        ko: "한국어",
        ru: "러시아어",
        hi: "힌디어",
        sw: "스와힐리어",
    },
};
